import React, {Component} from 'react'
import Layout from 'components/Layout'
import {Link} from 'gatsby'
import Australia from "images/australia.jpg"
import NewZealand from "images/New-Zealand.jpg"
import UKFlag from "images/uk.jpg"
import USFlag from "images/us.jpg"
import HomeMd from "images/booh-header-landing-1920.jpg"
import HomeSm from "images/booh-header-landing-991.jpg"
import MainLogo from "images/BOOH-1linelogo.png"
import YouTube from "react-youtube";
import styled from "styled-components";
import {media} from "utils/Media"
import Modal from "../components/Modal"
import SignUpForm from "../components/SignUpForm";
import Social from "../components/Social";


const headData = {
  title: 'Bat Out Of Hell!',
  description: 'The official site for the worldwide productions of Bat Out of Hell! The Musical based on the songs of Jim Steinman and Meat Loaf',
};

const VideoWrap = styled.div`
  // position: absolute;
  z-index: 1;
  width: 100%;
  display: block;
  top: 25%;
  left: 7%;
  padding: 10px;
  background: black;
  border: 3px solid #fbe800;
  @media ${media.md} {
    display: block;
    top: 25%;
    left: 7%;
  }

  iframe {
    max-width: 100%;
  }
  
  .footer__links--social {
    text-align: center;
  }

  /* &:before,
  &:after {
      content: "";
      background-color: black;
      height: 15%;
      width: 100%;
      position: absolute;
      bottom: -14%;
  }
  &:before {
      bottom: 100%;
  } */
`

class IndexPage extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false
    }
  }
  
  componentDidMount() {
    this.setState({
      modalOpen: false,
    });
  }
  
  toggleModal = (e) => {
    e.preventDefault();
    
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };
  
  render() {
    const facebook = "https://www.facebook.com/BatTheMusical/"
    const instagram = "https://www.instagram.com/batthemusical/?hl=en"
    const twitter = "https://twitter.com/batthemusical?lang=en"
    return (
      <>
        
        <Layout
          headData={headData}
          uk
        >
          <div className="main-wrapper">
            <img src={HomeSm} alt="Bat Out Of Hell!" className="img-fluid d-lg-none home-image"/>
            <img src={HomeMd} alt="Bat Out Of Hell!"
                 className="img-fluid d-none d-lg-block home-image"/>
            <div className="main-header--home">
              <div className="header-wrapper">
                
                <VideoWrap>
                  <Social
                    facebook={facebook}
                    twitter={twitter}
                    instagram={instagram}
                  />
                  <SignUpForm $homepage/>
                </VideoWrap>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
    
  }
}

export default IndexPage