import React from 'react'
import {Modal, ModalBody, Row, Col} from 'reactstrap'
import MeatLoaf from "images/meatloaf.jpg"
import styled from 'styled-components';
import { media } from '../utils/Media'

const StyledStrong = styled.strong`
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 700;
    font-size: 1.15rem;
    line-height: 1.35;
    color: ${props => props.theme.colors.yellow};
    max-width: 600px;
    span {
        margin-top: .5rem;
        display: block;
        font-size: 1.25rem;
        font-weight: 800!important;
        text-transform: uppercase;
    }

`
const WrapperStyles = styled.div`
    width: 100%;
    @media ${media.md} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
        }

    }
`

const VModal = (props) => (
    <Modal isOpen={props.isOpen} className="jim-modal" toggle={props.toggleModal}
           centered={true} size="xl">
        <button className="close-btn" onClick={props.toggleModal}>
            <span className="sr-only">Close</span>
        </button>
        <ModalBody>
            <WrapperStyles>    
                <StyledStrong >The company of Jim Steinman's Bat Out of Hell! the Musical here in the UK is deeply saddened to hear of the&nbsp;passing&nbsp;of their great friend and producer,<br /> <span>Meat Loaf.</span></StyledStrong>
                <br />
                <StyledStrong className='mb-4' ><em>The beat is yours forever</em>.</StyledStrong>
                <img src={MeatLoaf} alt="MeatLoaf" className='' />
            </WrapperStyles>    
        </ModalBody>
    </Modal>
);

export default VModal